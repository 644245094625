<template>
  <section class="full-height">

    <div class="columns is-multiline is-centered is-gapless is-marginless">      
      <div class="column is-10 is-offset-1 is-mobile is-narrow mt2rem">
        <div class="has-text-grey is-size-3 is-size-4-mobile wa-underlined-title">
          Run Server Manager
        </div>
      </div>
      
    </div>
    
    <div class="is-mobile is-gapless is-centered is-multiline m2rem" :updatedAt="updatedTimeStamp">
        <b-table
            :data="runTableData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableIsLoading"
            :mobile-cards="true">

            <b-table-column field="servers_id" label="id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servers_id }}
            </b-table-column>
            <b-table-column field="servers_name" label="name" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servers_name }}
            </b-table-column>
            <b-table-column field="servers_address" label="address" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servers_address }}
            </b-table-column>
            <b-table-column field="servermanage_state" label="state" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servermanage_state }}
                <b-button v-if="props.row.servermanage_state && props.row.servermanage_state !== 'ready'" :type="'is-success'"  class="smallbutton" @click="setReadyState(props.row)">SetReady</b-button>
            </b-table-column>
            <b-table-column field="servermanage_payload" label="payload" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servermanage_payload ? jsonFormat(getJSONValue(props.row.servermanage_payload)) : '' }}
                <b-button v-if="props.row.servermanage_state && props.row.servermanage_state !== 'ready'" :type="'is-success'"  class="smallbutton" @click="killRunningJob(props.row)">killRunningJob</b-button>
            </b-table-column>
            <b-table-column field="queueCount" label="Queue" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ typeof(props.row.queueCount) !== "undefined"  ? props.row.queueCount : '...' }}
            </b-table-column>
            <b-table-column field="cpuLoad" label="Load" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ typeof(props.row.cpuStats) !== "undefined" ? props.row.cpuStats : '...' }}
            </b-table-column>
            <b-table-column field="servermanage_updated_at" label="updated_at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servermanage_updated_at ? DDMMYYYY_HHii(new Date(props.row.servermanage_updated_at)) : '' }}
            </b-table-column>
            <b-table-column field="servermanage_updated_at" label="updated_at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servermanage_updated_at ? DDMMYYYY_HHii(new Date(props.row.servermanage_updated_at)) : '' }}
            </b-table-column>
            
            <b-table-column field="servers_isActive" label="is_active" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.servers_isActive }}
                <b-button :type="'is-success'"  class="smallbutton" @click="toggleActive(props.row, props.row.servers_isActive)">Toggle</b-button>
            </b-table-column>

         
            
            
            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
    
    
    
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
import { WEB_ROOT, API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

import { DDMMYYYY_HHii } from '@/helpers/dates.js';
const jsonFormat = require("json-format")

export default {
  name: "Ma", 
  components: {
  },  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      runServerData       : [],
      runTableData        : [],
      tableIsLoading      : false,
      WEB_ROOT            : WEB_ROOT,
      API_ROOT            : API_ROOT,
      SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,
      updatedTimeStamp    : new Date()
    }
  },
  
  async mounted(){
    await this.getRunServerData()
  },
  
  methods: {
    DDMMYYYY_HHii, jsonFormat,
    async getRunServerData() {
      this.ShowPageLoading(15000)
      let response = await axios_api.get(`${API_ROOT}/maximus/servers/getList`, {});
      console.info(`response:`, response)
      // if (response.data.result !== 1) {
      //   this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
      //   this.HidePageLoading()
      //   return;
      // }
      this.runServerData  = response.data.payload.runServerData
      this.HidePageLoading()
      
      this.runTableData = this.runServerData
      for(let server of this.runTableData) {
        const serverStats = await this.getServerQueue(server.servers_id)
        console.info(`serverStats:`, serverStats)
        server.queueCount = serverStats.queueCount
        server.cpuStats = serverStats.cpuStats ? `${serverStats.cpuStats.cpu.load[0]}%` : '??'
        this.updatedTimeStamp = new Date()
      }
    },
    
    
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    /* eslint-disable-next-line */        
    dateThAttrs(column) {
      return null
    },
            
    /* eslint-disable-next-line */
    columnTdAttrs(row, column) {
      return null
    },
    
    getJSONValue(str){
      try {
        if (!str || typeof(str) !== "string" || str.length <=2) return {}
        
        return JSON.parse(str)
      
      } catch (err) {
        console.info(`Error getJSONValue:`, err.message); 
        return { 'error VUE': `unable to parse json ??: ${str}`}
      }
    },
    
    async toggleActive(rowProps, curActiveState){
      console.info(`toggleActive rowProps:`, rowProps)
      console.info(`toggleActive curActiveState:`, curActiveState)
      
      const newState = parseInt(curActiveState) === 1 ? 0 : 1
      const newStateText = newState === 1 ? 'Active' : 'inActive'
      
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/servers/setState`, {
        serverId: rowProps.servers_id,
        newState: newState
      })
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to change server: ${rowProps.servers_name} (${rowProps.servers_address}) to ${newStateText}`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getRunServerData()
    },
    
    async setReadyState(rowProps){
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/servers/setReadyState`, {
        serverId: rowProps.servers_id        
      })
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to change server: ${rowProps.servers_name} (${rowProps.servers_address}) to ready`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getRunServerData()
    },
    
    
    async killRunningJob(rowProps){
      this.ShowPageLoading(15000)
      let response = await axios_api.post(`${API_ROOT}/maximus/servers/killRunningJob`, {
        serverId: rowProps.servers_id        
      })
      
      if (response.data.result !== 1) {
        this.$buefy.dialog.alert({ title: 'Error', message: `unable to kill running job of server: ${rowProps.servers_name} (${rowProps.servers_address}) to ready`, type: 'is-danger' })
        this.HidePageLoading()
        return;
      }
      
      this.getRunServerData()
    },
    
    async getServerQueue(serverId) {      
      let response = await axios_api.post(`${API_ROOT}/maximus/servers/getQueueCount`, {
        serverId: serverId
      })
      
      if (response.data.result !== 1) {
        return '??';
      }
      
      const serverInfo = response.data.payload
      console.info(`serverInfo:`, serverInfo)
      
      return {
        queueCount: serverInfo.queueCount !== null ? serverInfo.queueCount : '??',
        cpuStats: serverInfo.cpuStats,
      }
    }
    
    
    
    
  },
};
</script>

<style>
    .smallbutton{
      padding: 2px;
      margin: 0px;
      margin-right: 10px;
      line-height: 20px;
      font-size: 12px;
      border: 1px solid #000;
      height: 22px;
    }
    .smallbutton:hover{
      color: black !important;
      border: 1px solid black;
    }

</style>